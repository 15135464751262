import styled from "styled-components"

import { Indent, media } from "src/uikit"

export const MissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 196px;
  background: #f6f6fb;

  .inner {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 403px;
  }

  .schema-container {
    max-width: 880px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 0 40px;
    bottom: -425px;

    p {
      margin: 0;
      margin-bottom: 24px;
      text-align: center;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.4px;
      color: #0a1c61;
    }

    img {
      max-width: 100%;
    }
  }

  @media ${media.tablet} {
    .schema-container {
      bottom: -489px;
    }
  }

  @media ${media.mobile} {
    margin-bottom: 64px;

    ${Indent} {
      margin-top: 0;
    }

    .inner {
      min-height: 320px;
    }

    .schema-container {
      bottom: -16px;
      padding: 0 16px;
    }
  }
`

export const MissionText = styled.div`
  margin-top: 80px;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;

  text-align: center;

  color: #0a1c61;
  width: 100%;
  max-width: 1000px;
  padding: 0 50px;
  z-index: 100;

  @media ${media.mobile} {
    font-size: 26px;
    line-height: 32px;
    margin-top: 40px;
    padding: 0 16px;
  }
`

export const MissionQuoteContainer = styled.div`
  max-width: 800px;
  width: 100%;
  background: #ffffff;
  border-radius: 13.7739px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  position: relative;
  z-index: 100;
  bottom: 0;
  margin: 0;
`

export const MissionQuoteContainerShadow = styled.div`
  background: rgba(39, 49, 142, 0.2);
  filter: blur(10.2536px);
  border-radius: 31.4029px;
  position: absolute;
  z-index: 50;
  height: 194px;
  max-width: 800px;
  height: 100%;
  width: calc(100% - 80px);
  bottom: -10px;
  left: 40px;
`

export const MissionCircles = styled.div`
  position: absolute;
  mix-blend-mode: normal;
  opacity: 0.08;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 99;

  @media ${media.tablet} {
    right: -347px;
  }

  @media ${media.mobile} {
    display: none;
  }
`

export const MissionQuote = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;

  text-align: center;
  letter-spacing: 0.4px;
  max-width: 470px;
  width: 100%;
  color: #142470;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`

export const MissionQuoteText = styled.div`
  margin: 0 50px;
  flex: 1;
  font-size: 20px;
  line-height: 32px;

  @media (max-width: 768px) {
    margin: 0 25px;
  }
`

export const MissionQuoteAuthor = styled.div`
  text-align: center;
  margin-top: 32px;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 32px;

  letter-spacing: 0.5px;

  color: #0a1c61;
`

export const LeftQuoteContainer = styled.span`
  flex: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media (max-width: 768px) {
    svg {
      height: 14px;
      width: 16px;
    }
  }
`
export const RightQuoteContainer = styled.span`
  flex: 0;

  display: flex;
  flex-direction: row;
  align-items: flex-end;

  @media (max-width: 768px) {
    svg {
      height: 14px;
      width: 16px;
    }
  }
`

export const AfterMissionTextContainer = styled.section`
  padding: 0 16px;
  margin-top: -64px;
  margin-bottom: 80px;

  .inner {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${media.tablet} {
    margin-bottom: 64px;
  }

  @media ${media.mobile} {
    margin-bottom: 48px;
  }
`

export const AfterMissionText = styled.p`
  font-size: 17px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #0a1c61;
  margin-bottom: 16px;
  max-width: 800px;
  width: 100%;

  :last-child {
    margin-bottom: 0;
  }

  @media ${media.tablet} {
    max-width: 608px;
    text-align: left;
  }
`
