/* eslint-disable react/display-name */

import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"

import * as S from "./styled"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <S.Paragraph>{children}</S.Paragraph>
    ),
    [BLOCKS.HEADING_4]: (node, children) => <S.Heading4>{children}</S.Heading4>,
    [BLOCKS.UL_LIST]: (node, children) => <S.List>{children}</S.List>,
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <S.ListItem>
        <span className="circle"></span> {children}
      </S.ListItem>
    ),
  },
}

const Feature = ({ heading, icon, text, index }) => {
  return (
    <S.Container>
      <div className="heading">
        <img
          className="lazyload"
          data-src={icon?.file?.url}
          alt={icon?.description}
        />
        <h2>
          {index}. {heading}
        </h2>
      </div>
      <div className="text">
        {documentToReactComponents(text.json, options)}
      </div>
    </S.Container>
  )
}

export default Feature
