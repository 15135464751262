import React from "react"

import { useHeaderColor } from "src/common/hooks"
import { Banner, Features } from "src/components/SecurityPage"

import Layout from "../layout"

const SecurityPage = ({ location }) => {
  const { inverse, isChangeColor } = useHeaderColor({
    defaultInverse: false,
    elem: { selector: "#transition", inverse: false },
  })

  const headerColor = {
    primaryColor: "#F6F6FB",
    secondaryColor: "#fff",
  }

  return (
    <Layout
      headerColor={headerColor}
      headerInverse={inverse}
      headerIsChangeColor={isChangeColor}
      location={location}
    >
      <Banner />
      <div
        style={{ position: "relative", top: "-196px" }}
        id="transition"
      ></div>
      <Features />
    </Layout>
  )
}

export default SecurityPage
