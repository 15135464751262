import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useLocale } from "src/common/hooks"
import { getNodeByLocale } from "src/common/getNodesByLocale"
import { Indent } from "src/uikit"
import Button from "src/newUikit/Button"
import {
  getModalClickHandler,
  DOWNLOAD_ASSET_MODAL,
} from "src/components/ModalsContainer/events"

import * as S from "./styled"
import Feature from "./components/Feature"

const Features = () => {
  const locale = useLocale()
  const data = useStaticQuery(graphql`
    query {
      locales: allContentfulSecurityMain {
        nodes {
          node_locale
          features {
            id
            heading
            icon {
              file {
                url
              }
              description
            }
            text {
              json
            }
          }
          downloadButtonLabel
          downloadReference {
            modalId
          }
        }
      }
    }
  `)

  const locales = getNodeByLocale(data.locales.nodes, locale)

  const handleClick = getModalClickHandler(DOWNLOAD_ASSET_MODAL, {
    modalId: locales.downloadReference.modalId,
  })

  return (
    <S.Container>
      <div className="inner">
        {locales.features.map((feature, i) => {
          if (i === 0 || i % 3 === 0) {
            return (
              <>
                <Feature key={feature.id} index={i + 1} {...feature} />
                <Indent key={`indent-before-${feature.id}`} lg={80} md={64} />
                <Button
                  key={`button-${feature.id}`}
                  type="button"
                  color="cyan"
                  onClick={handleClick}
                >
                  {locales.downloadButtonLabel}
                </Button>
                <Indent key={`indent-after-${feature.id}`} lg={80} md={64} />
              </>
            )
          }

          return (
            <>
              <Feature key={feature.id} index={i + 1} {...feature} />
              <Indent key={`indent-${feature.id}`} lg={120} md={80} sm={64} />
            </>
          )
        })}
      </div>
    </S.Container>
  )
}

export default Features
