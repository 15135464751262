import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Indent } from "src/uikit"
import { Modal } from "src/uikit/Modal"
import Bg from "src/images/company/circles.svg"
import { getNodeByLocale } from "src/common/getNodesByLocale"
import { useLocale } from "src/common/hooks"
import Button from "src/newUikit/Button"

import * as S from "./styled"

const Banner = () => {
  const locale = useLocale()
  const data = useStaticQuery(graphql`
    query {
      locales: allContentfulSecurityMain {
        nodes {
          node_locale
          heading
          schemaImage {
            file {
              url
            }
            description
          }
          schemaHintText
          schemaButtonLabel
        }
      }
    }
  `)

  const [isMobile, setIsMobile] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen(prevValue => !prevValue)
  }

  useEffect(() => {
    const handler = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }

    handler()

    window.addEventListener("resize", handler)

    return () => {
      window.removeEventListener("resize", handler)
    }
  }, [setIsMobile])

  const locales = getNodeByLocale(data.locales.nodes, locale)

  return (
    <>
      <S.MissionContainer>
        <div className="inner">
          <S.MissionText>{locales.heading}</S.MissionText>
          <Indent lg={-361} md={-425} sm={0} />
          <div className="schema-container">
            {isMobile ? (
              <>
                <p>{locales.schemaHintText}</p>
                <Button color="cyan" full onClick={handleClick}>
                  {locales.schemaButtonLabel}
                </Button>
              </>
            ) : (
              <>
                <S.MissionQuoteContainer>
                  <img
                    className="lazyload"
                    data-src={locales.schemaImage?.file?.url}
                    alt={locales.schemaImage?.description}
                  />
                </S.MissionQuoteContainer>
                <S.MissionQuoteContainerShadow />
              </>
            )}
          </div>
          <S.MissionCircles>
            <Bg />
          </S.MissionCircles>
        </div>
      </S.MissionContainer>
      <Modal id="schema-modal" isOpen={isOpen} onClose={handleClick}>
        <img
          className="lazyload"
          data-src={locales.schemaImage?.file?.url}
          alt={locales.schemaImage?.description}
        />
      </Modal>
    </>
  )
}

export default Banner
