import styled from "styled-components"

import { media } from "src/uikit"

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  border: 2px solid #dee0ed;
  padding: 64px;
  padding-bottom: 40px;

  .heading,
  .text {
    max-width: 512px;
    width: 100%;
  }

  .heading {
    display: flex;
    align-items: flex-start;
    margin-right: 48px;

    h2 {
      margin: 0;
      font-weight: 800;
      font-size: 40px;
      line-height: 48px;
      color: #142470;
    }

    img {
      margin-right: 24px;
    }
  }

  .text {
  }

  @media ${media.tablet} {
    max-width: 688px;
    flex-direction: column;
    padding: 40px 32px 16px 32px;

    .heading,
    .text {
      max-width: 624px;
    }

    .heading {
      margin-bottom: 48px;
      margin-right: 0;
    }
  }

  @media ${media.mobile} {
    max-width: 288px;
    padding: 24px 16px 0 16px;

    .heading {
      margin-bottom: 24px;

      h2 {
        font-size: 18px;
        line-height: 20px;
      }

      img {
        margin-right: 16px;
        width: 64px;
        height: 64px;
      }
    }
  }
`

export const Heading4 = styled.h4`
  margin: 0;
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.4px;
  color: #0a1c61;

  :last-child {
    margin-bottom: 16px;
  }

  & ~ & {
    margin-top: 24px;
  }

  p ~ & {
    margin-top: 24px;
  }
`

export const Paragraph = styled.p`
  margin: 0;
  margin-bottom: 8px;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.4px;
  color: #0a1c61;

  :empty {
    display: none;
  }
`

export const List = styled.ul`
  padding: 0;
  margin: 0;
  margin-bottom: 24px;
  list-style: none;
`

export const ListItem = styled.li`
  display: flex;
  margin-bottom: 8px;

  .circle {
    min-width: 6px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #313c9d;
    display: block;
    position: relative;
    top: 11px;
    margin-right: 16px;
  }
`
